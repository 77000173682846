import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import { useAuth } from "react-oidc-context";

import { NO_PARTNERS, NO_PARTNERS_URLS } from "./ErrorConstants";
import ErrorComponent from "./ErrorComponent";

const ErrorBoundary = () => {
  const error = useRouteError();
  const auth = useAuth();

  // Check if the error is a route error response
  if (isRouteErrorResponse(error)) {
    // Handle specific error messages
    if (error.data.message === NO_PARTNERS || error.data.message === NO_PARTNERS_URLS) {
      return <ErrorComponent errorData={error} />;
    }
  }

  // Handle token expiration or unauthorized errors
  if (error?.status === 401) {
    // Clear internal user cookie and redirect to login
    document.cookie =
      "isInternalUser=; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure";
    auth.signoutRedirect();
    return <div>Session expired. Redirecting to login...</div>;
  }

  // Fallback for unexpected errors
  return <div>Something went wrong: {error?.statusText || "Unknown error"}</div>;
};

export default ErrorBoundary;
