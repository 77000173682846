import * as Sentry from "@sentry/react";

export class LoggerService {
  /**
   * Sends a custom event to Sentry
   * @param {string} message - Event message
   * @param {string} level - Log level (e.g., 'error', 'warning', 'info')
   * @param {object} data - Additional data to attach to the event
   * @param {object} [tags] - Custom tags to categorize the event
   */
  static sendEvent = (message, level, data, tags = {}) => {
    const event = {
      message,
      level,
      extra: data,
      tags,
    };

    Sentry.captureEvent(event);
  };

  /**
   * Tracks a breadcrumb in Sentry
   * @param {string} message - Breadcrumb message
   * @param {string} category - Category for the breadcrumb
   * @param {string} level - Log level for the breadcrumb (e.g., 'info', 'error')
   * @param {object} data - Additional data for the breadcrumb
   */
  static addBreadcrumb = (message, category, level, data = {}) => {
    Sentry.addBreadcrumb({
      message,
      category,
      level,
      data,
    });
  };

  /**
   * Sets user context for Sentry
   * @param {object} user - User information (e.g., id, email, username)
   */
  static setUserContext = (user) => {
    Sentry.setUser(user);
  };

  /**
   * Clears the current user context in Sentry
   */
  static clearUserContext = () => {
    Sentry.setUser(null);
  };
}

export default LoggerService;
