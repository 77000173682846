import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { useAuth, hasAuthParams } from "react-oidc-context";
import axios from "axios";
import * as Sentry from "@sentry/react";

import "./index.css";
import ErrorBoundary from "./components/errors/ErrorBoundary";

import Home from "./routes/Home";
import Settings from "./routes/Settings";
import InternalDomoReport from "./routes/InternalDomoReport";
import LoggerService from "./services/LoggerService";

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
  tunnel: `${process.env.REACT_APP_LAMBDA_PROXY_ENDPOINT}`,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    "*.fluentco.com",
    "*.minionplatform.com",
  ],
});

function App() {
  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  const auth = useAuth();

  // Fetch user data
  const getUserData = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.user.access_token}`,
          Accept: "*/*",
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_HOSTNAME}/v1/account/profile`,
        config
      );
      const defaultClient = data?.partners?.[0]?.name || "";

      LoggerService.sendEvent("user_data_fetched", "info", {
        user: data,
        defaultClient,
      });

      return { ...data, defaultClient };
    } catch (error) {
      if (error.response?.status === 401) {
        LoggerService.sendEvent("token_expired", "info", { error });
        auth.signoutRedirect();
      } else {
        LoggerService.sendEvent("user_data_fetch_error", "error", {
          error,
        });
      }
      throw error;
    }
  };

  // Sentry-wrapped router
  const router = sentryCreateBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorBoundary />,
      loader: async () => await getUserData(),
    },
    {
      path: "settings",
      element: <Settings />,
      errorElement: <ErrorBoundary />,
      loader: async () => await getUserData(),
    },
    {
      path: "internal-domo-report",
      element: <InternalDomoReport />,
      errorElement: <ErrorBoundary />,
      loader: async () => await getUserData(),
    },
  ]);

  // Handle token expiration
  useEffect(() => {
    const checkTokenExpiration = () => {
      if (!auth.isAuthenticated || auth.user?.expired) {
        LoggerService.sendEvent("token_check_expired", "warning", {
          message: "Token has expired",
        });
        document.cookie =
          "isInternalUser=; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure";
        auth.signoutRedirect();
      }
    };

    auth.events.addAccessTokenExpired(checkTokenExpiration);

    return () => {
      auth.events.removeAccessTokenExpired(checkTokenExpiration);
    };
  }, [auth]);

  // Redirect to login if not authenticated
  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.isLoading) {
      const params = new URLSearchParams(window.location.search);
      const idpHint = params.get("idpHint");

      if (idpHint) {
        LoggerService.sendEvent("redirecting_with_idp_hint", "info", {
          idpHint,
        });

        auth.signinRedirect({
          extraQueryParams: { kc_idp_hint: idpHint },
        });
        document.cookie = "isInternalUser=true; SameSite=None; Secure";
      } else {
        LoggerService.sendEvent("redirecting_to_auth", "info", {
          message: "Redirecting to authentication",
        });

        auth.signinRedirect();
      }
    }
  }, [auth.isAuthenticated, auth.isLoading, hasAuthParams]);

  // Handle authentication and app states
  switch (auth.activeNavigator) {
    case "signinSilent":
      LoggerService.addBreadcrumb("Auth: Signing in silently", "auth", "info");
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      LoggerService.addBreadcrumb("Auth: Signing out", "auth", "info");
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    LoggerService.addBreadcrumb("Auth: Loading state", "auth", "info");
    return <div>Loading...</div>;
  }

  if (auth.error) {
    LoggerService.sendEvent("auth_error", "error", {
      error: auth.error,
      userProfile: auth.user?.profile,
    });
    return <div>Oops... {auth.error.message}</div>;
  }

  return <RouterProvider router={router} />;
}

export default App;
