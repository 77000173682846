import React, { useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { Box, CssBaseline, AppBar, Toolbar, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Header from "../components/Header/Header";
import SideNavigationBar from "../components/SideNavigationBar/SideNavigationBar";
import { useLoaderData } from "react-router-dom";
import DomoReport from "../components/DomoReport/DomoReport";
import MetaTags from "../components/MetaTags/MetaTags";

const drawerWidth = 240;

const Home = () => {
  const [selectedClient, setSelectedClient] = useState("");
  const [authError, setAuthError] = useState(false);

  const auth = useAuth();
  const userData = useLoaderData();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Home -> useEffect()");

    if (!auth.isAuthenticated) {
      // Handle invalid session
      setAuthError(true);
      return;
    }

    setSelectedClient(userData.defaultClient);
  }, []);

  const handleClientChange = (newState) => {
    setSelectedClient(newState);
  };

  const handleRetryLogin = () => {
    auth.signinRedirect(); // Redirect the user to the login page
  };

  return (
    <>
      <MetaTags
        title="Fluent Pulse I AI-powered advertising & monetization platform"
        description="Leverage first-party data and advanced machine learning to deliver personalization and enhance brand connections with online shoppers."
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            background: "#18136C",
          }}
        >
          <Toolbar>
            <Header
              data={userData}
              client={selectedClient}
              onChange={handleClientChange}
            />
          </Toolbar>
        </AppBar>
        <SideNavigationBar data={userData} />
        <Box
          sx={{
            width: "100%",
            height: "100vh", // Ensure full height
            marginTop: "130px", // Adjust according to your layout
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {authError ? (
            // Render an error message with a "Retry Login" button
            <Box textAlign="center">
              <Typography variant="h6" color="error">
                The email and password combination are invalid. Please log in again.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={handleRetryLogin}
              >
                Retry Login
              </Button>
            </Box>
          ) : selectedClient ? (
            <DomoReport client={selectedClient} />
          ) : (
            <Typography variant="h6" color="error" align="center">
              Please contact your account manager to give you permission to see the report.
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Home;
