import React, { useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useLoaderData, useSearchParams } from "react-router-dom";
import axios from "axios";
import ErrorComponent from "../components/errors/ErrorComponent";
import {
  NO_PARTNERS,
  NO_PARTNERS_URLS,
} from "../components/errors/ErrorConstants";
import LoggerService from "../services/LoggerService";

const InternalDomoReport = () => {
  const [htmlContent, setHtmlContent] = useState("");
  const [error, setError] = useState(false);
  const [hasNoPartnersUrlError, setHasNoPartnersUrlError] = useState(false);
  const [hasNoPartnersError, setHasNoPartnersError] = useState(false);

  const userData = useLoaderData();
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const partnerName = searchParams.get("client");

  // Retrieve initial active tab per partner
  const getInitialActiveTab = () => {
    const storedTab = localStorage.getItem(`activeTab-${partnerName}`);
    return storedTab !== null ? parseInt(storedTab) : 0;
  };

  const [activeTab, setActiveTab] = useState(getInitialActiveTab);

  useEffect(() => {
    // Reset active tab when the partner changes
    if (partnerName) {
      const storedTab = localStorage.getItem(`activeTab-${partnerName}`);
      if (storedTab === null) {
        setActiveTab(0);
        localStorage.setItem(`activeTab-${partnerName}`, 0);
      }
    }
  }, [partnerName]);

  useEffect(() => {
    const fetchUrl = async (url) => {
      try {
        const { data } = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${auth.user.access_token}`,
            Accept: "*/*",
          },
        });
        setHtmlContent(data);
      } catch (error) {
        setError(true);
        LoggerService.sendEvent("portal_report_show_error", "error", {
          error,
          userProfile: auth.user.profile,
        });
      }
    };

    if (userData && userData.defaultClient && userData.partners?.length) {
      const partner = userData.partners.find(
        (partner) => partner.name === partnerName
      );

      if (partner?.urls?.length) {
        const currentUrl = partner.urls[activeTab]?.url;
        if (currentUrl) fetchUrl(currentUrl);
      } else {
        setHasNoPartnersUrlError(true);
        LoggerService.sendEvent("portal_report_no_partners_urls", "error", {
          userProfile: auth.user.profile,
        });
      }
    } else {
      setHasNoPartnersError(true);
      LoggerService.sendEvent("portal_report_no_partners_assigned", "error", {
        userProfile: auth.user.profile,
      });
    }
  }, [activeTab]);

  const handleFormSubmit = () => {
    const form = document.getElementById("form");
    if (form) {
      form.submit();
    }
  };

  useEffect(() => {
    if (htmlContent) {
      handleFormSubmit();
    }
  }, [htmlContent]);

  const handleTabClick = (index) => {
    setActiveTab(index);
    localStorage.setItem(`activeTab-${partnerName}`, index);
  };

  if (hasNoPartnersError) {
    return (
      <div className="container">
        {renderTabs()}
        <ErrorComponent errorData={{ message: NO_PARTNERS }} />
      </div>
    );
  }

  if (hasNoPartnersUrlError) {
    return (
      <div className="container">
        {renderTabs()}
        <ErrorComponent errorData={{ message: NO_PARTNERS_URLS }} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="container">
        {renderTabs()}
        <ErrorComponent
          errorData={{
            message: `An error occurred loading the report for ${partnerName}`,
          }}
        />
      </div>
    );
  }

  return (
    <div className="container">
      {renderTabs()}
      <iframe
        className="content-container"
        srcDoc={htmlContent}
        onLoad={() => handleFormSubmit()}
      />
    </div>
  );

  function renderTabs() {
    return (
      <div className="tabs">
        {userData.partners
          .find((partner) => partner.name === partnerName)
          ?.urls.map((url, index) => (
            <button
              key={index}
              className={`tab ${activeTab === index ? "active" : ""}`}
              onClick={() => handleTabClick(index)}
            >
              {`${url.name}`}
            </button>
          ))}
      </div>
    );
  }
};

export default InternalDomoReport;
