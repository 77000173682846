import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Menu,
  TextField,
  Typography,
} from "@mui/material";
import leftRightArrows from "../../assets/arrow-left-right-line.svg";

const DEFAULT_PARTNER_TEXT = "Change Partner";

const Header = ({ data, client, onChange, isSettingsPage }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(client);
  const [isMultiPartnerUser, setIsMultiPartnerUser] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (text) => {
    setSelectedItem(text);
    onChange(text);
    handleClose();
  };

  useEffect(() => {
    if (data?.partners && data.partners.length > 1) {
      setIsMultiPartnerUser(true);
    }

    // Safeguard: If data.partners is not defined, don't try to access it
    if (data?.partners?.length > 0) {
      setSelectedItem(data.partners[0].name || DEFAULT_PARTNER_TEXT); // default if name is not available
    }
  }, []);
  
  return (
    <>
      <Typography variant="h6" noWrap component="div">
        Welcome {data?.firstName || "Guest"} {/* Fallback for firstName */}
      </Typography>
      <div
        style={
          isSettingsPage
            ? { marginLeft: "auto", display: "none" }
            : { marginLeft: "auto" }
        }
      >
        {isMultiPartnerUser ? (
          <>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{ color: "white" }}
              onClick={handleClick}
            >
              {selectedItem}
              <img src={leftRightArrows} style={{ paddingLeft: "10px" }} />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Autocomplete
                freeSolo
                options={data?.partners?.map((partner) => partner.name) || []} // Ensure partners is an array
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Search"
                    sx={{ width: 200 }}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                    inputProps={{
                      ...params.inputProps,
                      "data-testid": "search-input",
                    }}
                  />
                )}
                onChange={(event, value) => {
                  if (
                    value &&
                    !data?.partners?.find((partner) => partner.name === value)
                  ) {
                    // render something here?
                    setSelectedItem(DEFAULT_PARTNER_TEXT);
                  } else {
                    handleMenuItemClick(value);
                  }
                }}
              />
            </Menu>
          </>
        ) : (
          <span style={{ fontSize: "1.25rem", fontWeight: "500" }}>
            {selectedItem}
          </span>
        )}
      </div>
    </>
  );
};

export default Header;
